<template lang="pug">
  main.white-bg
    .inside-content
      .container
        article.article
          .article__header
            .article__header-info
              .article__header-content
                h1 Расчет теплопотерь дома онлайн

              .article__header-img
                img(src="~images/blog/house.svg" style="margin: 15% auto; width: 40%")

          .article__content
            .article__sidebar
              .article__meta
                .article__meta-item
                  span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                  span.article__meta-item-text Время чтения 3 минуты

            .article__content-text

              h2 Как можно посчитать теплопотери?

              p Наш онлайн калькулятор теплопотерь позволяет сделать расчет в двух вариантах: <strong>Упрощенный</strong> или <strong>Подробный</strong>.

              h2 Упрощенный расчет теплопотерь

              p Здесь мы определяем теплопотери по “кубатуре” помещения на основе нормативных документов по энергоэффективности. Если ваш дом проектировал архитектор, и в нём нет помещений с большими витражами, наклонными потолками, и с прочими нестандартными решениями — упрощенный расчет будет достаточно точным.
              p <strong>Как сделать?</strong>
              ol
                li Вводите название проекта.
                li Выбираете регион строительства.
                li Указываете комфортные температуры в доме.
                li Создаете помещение, вводите его объем в м
                  sup 3
                li Видите результат расчета теплопотерь.

              h2 Подробный расчет теплопотерь

              p В этом варианте производится точный расчет термических сопротивлений и теплопотерь. Вы должны будете указать состав ваших стен (послойно) и толщины слоев. Это не сложно — у нас обширные справочники строительных материалов.
              p Для полного теплотехнического расчета вам нужно знать площади стен, окон, пола, крыши/чердачного перекрытия - всех ограждающих конструкций вашего дома. Ограждающие конструкции ограждают отапливаемые помещения от улицы.
              p <strong>Как сделать?</strong>
              p 1. Сначала мы создаем ОК здания (ограждающие конструкции) и послойно их описываем, начиная с наружного слоя. Например, стена наружная может состоять из 3-х слоев: Облицовочный кирпич – 120мм, теплоизоляция – 100мм, газоблок – 300мм. В нашем справочнике строительные материалы сгруппированы по классам (бетоны, растворы, минеральные ваты) и по брендам (Технониколь, Теплокнауф, Ytong).  Все бренды на сайте верифицированы. Мы не добавляем материалы производителей, у которых нет протоколов испытаний. Если вы не нашли свой материал в нашем справочнике, можно ввести коэффициент теплопроводности вручную.
              p 2. После того, как ограждающая конструкция описана, она автоматически проверяется на соответствие нормам энергоэффективности для вашего региона. Например, если термическое сопротивление вашей стены не дотягивает до нормы – вы увидите это в результатах расчета. Мы даже покажем, какая при этом будет температура в помещении.
              p 3. А дальше вы создаете помещение и прикрепляете к нему ранее описанные ограждающие конструкции.
              p Поставьте галочку «Учитывать вентиляцию», если вы не планируете установку с подогревом наружного воздуха. Подсказки по нормам воздухообмена помогут определиться с количеством воздуха для комфортной жизни. Смотрите на нашем Youtube канале короткое видео - «Почему в современных коттеджах без вентиляции нельзя?».
              h2 У вас уже есть расчет теплопотерь?
              p Тогда введите теплопотери для каждого помещения и приступайте к расчету теплого пола и радиаторов.

              h2 Выводы и рекомендации
              p 1. Хотите сэкономить на оборудовании – делайте подробный расчет теплопотерь.
              p 2. По статистике, 70% наших пользователей выбирают подробный расчет теплопотерь, потому что заинтересованы в экономии на материалах и оборудовании.
              p 3. Люди, которые считают теплопотери по формуле 100Вт на 1 м
                sup 2&nbsp;
                | пола, полагаются на волю случая. Они могут переплатить за оборудование, а еще хуже мерзнуть зимой. Грамотный специалист, всегда приложит к своему коммерческому предложению полный расчет теплопотерь.

              .download(id="lightgallery")
                .download__content
                  .download__text
                    | 4. Результат расчета теплопотерь формируется в виде ведомости, оформленной по ГОСТ. В штампе вы можете указать свое имя, как разработчика.
                  a.download__text-link(href="/heatloss.pdf", target="_blank") скачать (.PDF 98kb)
                .download__img
                  enlargeable-image(:src="downloadImg" :src_large="downloadImg" animation_duration="300")
                    span
                      img(src="~/images/heatloss_small.jpg", alt="")

              blockquote
                h2 Всё по ГОСТам и СНИПам
                p Мы полностью соответствуем нормативным документам.
                p Если в процессе расчета вы от них отклонитесь - мы сразу предупредим и подскажем как поправить расчет.

              .article__register
                a.button.button_green(@click="goSignUp") Регистрация

              //.article__likes
                .article__likes-title Этот материал был вам полезен?
                .article__likes-buttons
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Да
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 9.75h4.5v9.75H3a.75.75 0 01-.75-.75V10.5A.75.75 0 013 9.75z" fill="#696E8A"/><path d="M21.74 7.51a2.25 2.25 0 00-1.69-.76H15v-1.5a3.75 3.75 0 00-3.75-3.75.75.75 0 00-.67.41L7.04 9H3a1.5 1.5 0 00-1.5 1.5v8.25a1.5 1.5 0 001.5 1.5h15.93a2.25 2.25 0 002.23-1.97l1.12-9a2.25 2.25 0 00-.54-1.77zM3 10.5h3.75v8.25H3V10.5zm17.8-1.4l-1.13 9a.75.75 0 01-.74.65H8.25V9.93l3.44-6.89a2.25 2.25 0 011.8 2.21V7.5a.75.75 0 00.76.75h5.8a.75.75 0 01.74.84z" fill="#696E8A"/></svg>
                  button.article__likes-button(type="button")
                    span.article__likes-button-text Нет
                    span.article__likes-button-icon <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><path opacity=".2" d="M3 14.25h4.5V4.5H3a.75.75 0 00-.75.75v8.25a.75.75 0 00.75.75z" fill="#696E8A"/><path d="M21.74 16.49a2.25 2.25 0 01-1.69.76H15v1.5a3.75 3.75 0 01-3.75 3.75.75.75 0 01-.67-.41L7.04 15H3a1.5 1.5 0 01-1.5-1.5V5.25A1.5 1.5 0 013 3.75h15.93a2.25 2.25 0 012.23 1.97l1.12 9a2.25 2.25 0 01-.54 1.77zM3 13.5h3.75V5.25H3v8.25zm17.8 1.4l-1.13-9a.75.75 0 00-.74-.65H8.25v8.82l3.44 6.89a2.25 2.25 0 001.8-2.21V16.5a.75.75 0 01.76-.75h5.8a.75.75 0 00.74-.84z" fill="#696E8A"/></svg>

        section.related
          .related__title Другие статьи

          .prev-next
            a.prev-next__item(@click="goFloor") Предыдущая
            a.prev-next__item(@click="goWhy") Следующая

          flickity.related__grid(ref="flickity" :options="flickityOptions")
            article.article-card
              a.article-card__img(@click="goWhy")
                img(src="~images/blog/article-img.png")

              .blog-card__content
                a.blog-card__title(@click="goWhy") Зачем нужны расчеты
                .blog-desc
                  p Думаем, ни для кого не секрет, что подавляющее большинство частных домов строится не только без проекта, но и вообще без расчетов.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 10 минут

                  a.blog-card__read-more(@click="goWhy")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goRadiator")
                img(src="~images/blog/radiator.svg" style="width: 50%")

              .blog-card__content
                a.blog-card__title(@click="goRadiator") Расчет радиаторов
                .blog-desc
                  p В советское время выбор радиатора (прибора отопления) был предопределен. Вот вам чугунная батарея – грейтесь.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 4 минуты

                  a.blog-card__read-more(@click="goRadiator")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

            article.article-card
              a.article-card__img(@click="goFloor")
                img(src="~images/blog/warm-floor.svg" style="width: 50%")

              .blog-card__content
                a.blog-card__title(@click="goFloor") Расчет теплого пола
                .blog-desc
                  p Водяной теплый пол — это залитые в бетон трубы. Если что-то сделано неправильно, устранение ошибки обойдется очень дорого.

                .article__meta
                  time.article__meta-item
                    span.article__meta-item-text 02.01.2021
                  .article__meta-item
                    span.article__meta-item-icon <svg viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="7" cy="7" r="6" stroke="#6E7288"/><path d="M7 11v1M7 2v1M2 7h1M11 7h1M7 4v3.6L9.4 10" stroke="#6E7288"/></svg>
                    span.article__meta-item-text 5 минут

                  a.blog-card__read-more(@click="goFloor")
                    span.blog-card__read-more-text Читать  дальше
                    span.blog-card__read-more-arrow <svg viewBox="0 0 25 8" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M24.35 4.35a.5.5 0 000-.7L21.17.46a.5.5 0 10-.7.71L23.28 4l-2.83 2.83a.5.5 0 10.71.7l3.18-3.18zM0 4.5h24v-1H0v1z" fill="#505571"/></svg>

</template>

<script>
import Flickity from 'vue-flickity'
import navigationMixin from 'mixins/navigation'
import EnlargeableImage from '@diracleo/vue-enlargeable-image'

export default {
  name: 'RaschetTeplopoter',
  mixins: [navigationMixin],
  data () {
    return {
      flickityOptions: {
        groupCells: true,
        wrapAround: false,
        contain: true,
        autoPlay: false,
        adaptiveHeight: false,
        prevNextButtons: false,
        pageDots: false,
        resize: false
      },
      downloadImg: require('../../assets/images/heatloss-example.jpg')
    }
  },
  components: {
    Flickity,
    EnlargeableImage
  },
  metaInfo: {
    title: 'Точный расчет теплопотерь дома. Онлайн-калькулятор.',
    meta: [
      {
        name: 'description',
        content: 'Профессиональный теплотехнический расчет ограждающих конструкций. Расчет нагрузки на вентиляцию. Большой справочник строительных материалов.'
      }
    ]
  }
}
</script>
